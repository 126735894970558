import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
} from "./types";
import { notification } from "antd";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";

// Load user
export const loadUser = () => async (dispatch) => {
  const url = process.env.REACT_APP_API_URL_FRONT;
  try {
    const res = await axios.get(`${url}/operator/users`);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (phoneNumber, password) => async (dispatch) => {
  const url = process.env.REACT_APP_API_URL_FRONT;
  const config = {
    headers: {
      "Accept-Language": "az",
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ phoneNumber, password });

  try {
    const res = await axios.post(`${url}/login`, body, config);
    console.log("Login successful:", res.data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    window.location.reload(true);
  } catch (err) {
    console.error("Login failed:", err);

    notification.error({
      message: "Xəta",
      description: "Yanlış nömrə və ya şifrə",
    });
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
